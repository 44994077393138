<mat-action-list
	id="menu"
	(click)="CloseMenu()"
	[ngClass]="{
	'app-light-theme': !themeService.isDark,
	'app-dark-theme': themeService.isDark
	}"
>
	<nav [attr.aria-label]="'Controls.Menu' | translate">
		<a
			mat-list-item
			fxLayoutAlign="end"
			[appA11yActivate]="{ method: 'CloseMenu', context: this }"
			tabindex="0"
			class="mat-subheader"
			[attr.aria-label]="'Common.Controls.Close' | translate"
		>
			{{ "Common.Controls.Close" | translate }}&nbsp;&nbsp;
			<mat-icon>close</mat-icon>
		</a>
		<mat-divider></mat-divider>

		<ng-container *ngIf="(adminEntries | async)?.length">
			<a
				mat-list-item
				routerLink="/admin"
				class="mat-subheader"
				[attr.aria-label]="'Admin.ShortTitle' | translate"
			>
				{{ "Admin.ShortTitle" | translate }}
				<mat-icon matListIcon>admin_panel_settings</mat-icon>
			</a>
			<mat-divider></mat-divider>
		</ng-container>

		<a
			mat-list-item
			routerLink="/search"
			class="mat-subheader"
			[attr.aria-label]="'Home.Sections.Search' | translate"
		>
			{{ "Home.Sections.Search" | translate }}
			<mat-icon matListIcon>search</mat-icon>
		</a>
		<mat-divider></mat-divider>

		<a
			mat-list-item
			class="mat-subheader"
			[appA11yActivate]="{ method: 'OpenProfile', context: this }"
			tabindex="0"
			[attr.aria-label]="GetFormattedUserName(profileName)"
		>
			{{ GetFormattedUserName(profileName) }}
			<mat-icon class="user-profile-icon" matListIcon>account_circle</mat-icon>
		</a>
		<mat-divider></mat-divider>

		<mat-expansion-panel (click)="$event.stopPropagation()">
			<mat-expansion-panel-header>
			<mat-panel-title
				[title]="'Home.MenuAltText' | translate"
			>
				<a
					[appA11yActivate]="{
						method: 'OpenRouterLink',
						params: '/categories',
						context: this
					}"
					(keydown.enter)="$event.stopPropagation()"
					(keydown.space)="$event.stopPropagation()"
					tabindex="0"
					[attr.aria-label]="'Home.Title' | translate"
				>
					{{ "Home.Title" | translate }}
				</a>
			</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-divider></mat-divider>

			<a
				mat-list-item
				routerLink="/categories"
				class="mat-subheader"
				(click)="CloseMenu()"
				[attr.aria-label]="'Home.Sections.Categories.md' | translate"
			>
				{{ "Home.Sections.Categories.md" | translate }}
			</a>
			<mat-divider></mat-divider>

			<a
				mat-list-item
				routerLink="/bookmarked"
				class="mat-subheader"
				(click)="CloseMenu()"
				[attr.aria-label]="'Home.Sections.Bookmarks.md' | translate"
			>
				{{ "Home.Sections.Bookmarks.md" | translate }}
			</a>
			<mat-divider></mat-divider>

			<a
				mat-list-item
				routerLink="/latest-reports-and-news"
				class="mat-subheader"
				(click)="CloseMenu()"
				[attr.aria-label]="'Home.Sections.LatestReportsAndNews.md' | translate"
			>
				{{ "Home.Sections.LatestReportsAndNews.md" | translate }}
			</a>
			<mat-divider></mat-divider>

			<ng-container *ngIf="hasBetaReports">
				<a
					mat-list-item
					[routerLink]="['/categories/category', { categoryKey: 'beta' }]"
					class="mat-subheader"
					(click)="CloseMenu()"
					[attr.aria-label]="'Home.BetaReports.Title' | translate"
				>
					{{ "Home.BetaReports.Title" | translate }}
				</a>
				<mat-divider></mat-divider>
			</ng-container>

			<ng-container *ngIf="hasFactbooks">
				<a
					mat-list-item
					routerLink="/pirl"
					class="mat-subheader"
					(click)="CloseMenu()"
					[attr.aria-label]="'Factbooks.Title' | translate"
				>
					{{ "Factbooks.Title" | translate }}
				</a>
			<mat-divider></mat-divider>
			</ng-container>
		</mat-expansion-panel>
		<mat-divider></mat-divider>

		<a
			mat-list-item
			routerLink="/about"
			class="mat-subheader"
			[attr.aria-label]="'AboutUs.Title' | translate"
			>{{ "AboutUs.Title" | translate }}</a
		>
		<mat-divider></mat-divider>

		<mat-expansion-panel (click)="$event.stopPropagation()">
			<mat-expansion-panel-header>
				<mat-panel-title 
					[title]="'Home.MenuAltText' | translate"
				>
					<a
						[appA11yActivate]="{
							method: 'OpenRouterLink',
							params: '/help',
							context: this
						}"
						(keydown.enter)="$event.stopPropagation()"
						(keydown.space)="$event.stopPropagation()"
						tabindex="0"
						[attr.aria-label]="'Help.Title' | translate"
					>
						{{ "Help.Title" | translate }}
					</a>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-divider></mat-divider>

			<a
				mat-list-item
				routerLink="/glossary"
				class="mat-subheader"
				(click)="CloseMenu()"
				[attr.aria-label]="'Glossary.Title' | translate"
			>
				{{ "Glossary.Title" | translate }}
			</a>
			<mat-divider></mat-divider>
			
			<a
				mat-list-item
				class="mat-subheader"
				(click)="CloseMenu(); ShowTour()"
				(keydown.enter)="CloseMenu(); ShowTour()"
				(keydown.space)="CloseMenu(); ShowTour()"
				[attr.aria-label]="'Common.Titles.Tour' | translate"
				tabindex="0"
			>
				{{ "Common.Titles.Tour" | translate }}
			</a>
			<mat-divider></mat-divider>

			<a
				mat-list-item
				routerLink="/user-guides"
				class="mat-subheader"
				(click)="CloseMenu()"
				[attr.aria-label]="'UserGuides.Title' | translate"
			>
				{{ "UserGuides.Title" | translate }}
			</a>
			<mat-divider></mat-divider>

			<a
				mat-list-item
				routerLink="/videos"
				class="mat-subheader"
				(click)="CloseMenu()"
				[attr.aria-label]="'VideoGallery.Title' | translate"
			>
				{{ "VideoGallery.Title" | translate }}
			</a	>
		</mat-expansion-panel>
		<mat-divider></mat-divider>

		<a
			mat-list-item
			routerLink="/accessibility"
			class="mat-subheader"
			[attr.aria-label]="'Accessibility.Title' | translate"
		>
			{{ "Accessibility.Title" | translate }}
		</a>
		<mat-divider></mat-divider>

		<a
			mat-list-item
			routerLink="/terms"
			class="mat-subheader"
			[attr.aria-label]="'Terms.Title' | translate"
		>
			{{ "Terms.Title" | translate }}
		</a>
		<mat-divider></mat-divider>

		<a
			mat-list-item
			routerLink="/contact"
			class="mat-subheader"
			[attr.aria-label]="'ContactUs.Title' | translate"
		>
			{{ "ContactUs.Title" | translate }}
		</a>
	</nav>
</mat-action-list>
