<mat-card *ngIf="viewModel" fxLayoutAlign="space-between" fxLayout="column" tabindex="-1" [ngClass]="{'mat-card-list': isInList && !isLowRes, 'mat-card-no-line': isBookmarkPage && !isBookmarked, 'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}" role="cell">
	<div *ngIf="!isInDialog" class="top-line"></div>
	<mat-card-header fxLayoutAlign="start center" fxLayout="column">
		<mat-card-title>
			<div 
				*ngIf="!isFactbook; else factbookTitle" 
				tabindex="0" 
				[appA11yActivate]="{method:'OpenReport', params:this.viewModel.url.address, context:this}" 
				matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}" 
				appEllipsify [innerHtml]="highlight(viewModel.title)"
				role="note">
			</div>
		</mat-card-title>
		<mat-divider aria-hidden="true"></mat-divider>
	</mat-card-header>
	<div class="card-content" [ngClass]="{'card-grid': !isInList}" fxLayout="{{isInList && !isLowRes ? 'row' : 'column'}}" fxLayoutGap="10px">
		<div class="card-info" fxFlex.gt-lg="25" fxFlex.lt-xl="25">
			<div *ngIf="viewModel.dataOwner.address" class="truncate" appEllipsify>{{'Reports.DataOwner' | translate}}: <a *ngIf="viewModel.dataOwner.address" target="_blank" rel="noopener noreferrer" href="{{viewModel.dataOwner.address}}" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}">{{viewModel.dataOwner.description }}</a></div>
			<div *ngIf="factbookDataOwner" class="truncate" appEllipsify>{{'Reports.DataOwner' | translate}}: {{factbookDataOwner.beforeText}}<a target="_blank" href="mailto:sims@ontario.ca" matTooltip="{{'Factbooks.DataOwnerUnknownTitle' | translate}}">sims@ontario.ca</a>{{factbookDataOwner.afterText }}</div>
			<div>{{'Reports.Updated' | translate}}: {{viewModel.published | dateFormatted }}</div>
			<div *ngIf="isInDialog && !isFactbook">{{(categories.length > 1 ? 'Home.Sections.Categories.sm' : 'Reports.Category') | translate}}: <span *ngFor="let cat of categories; let isLast=last" class="category">{{cat.title}}{{isLast ? '' : ', '}}</span></div>
			<mat-divider *ngIf="!isInList" aria-hidden="true"></mat-divider>
		</div>
		<mat-divider *ngIf="isInList" class="vertical-divider" [vertical]="true" aria-hidden="true"></mat-divider>
		<div 
			class="description-container" 
			fxFlex="auto" 
			fxFlex.gt-lg="{{isInList ? 'auto' : '' }}" 
			fxLayout.gt-lg="{{isInList && !isLowRes ? 'row' : 'column'}}" 
			tabindex="0" 
			role="note"
		>
			<div *ngIf="description.length" fxHide fxShow.lt-lg [innerHtml]="description">
				<div *ngIf="!isInDialog &&!isFactbook" [appA11yActivate]="{method:'viewMore', context:this}" class="view-more" tabindex="0" role="note">{{'Reports.ViewMore' | translate}}</div>
			</div>

			<div fxHide fxShow.gt-md="{{!isInDialog}}" class="description">
				<div *ngIf="description.length" class="croppedTexts" [innerHtml]="description"></div>
				<div *ngIf="!isInDialog &&!isFactbook" [appA11yActivate]="{method:'viewMore', context:this}" class="view-more"  tabindex="0" role="note">{{'Reports.ViewMore' | translate}}</div>
			</div>

			<div fxHide fxShow.gt-md="{{ !isInList && isInDialog && description.length }}" fxFlex.gt-lg="{{isInList ? 'auto' : '' }}" class="description" [innerHtml]="description"></div>
		</div>
		<div *ngIf="isInDialog" [attr.aria-label]="'Reports.Tags' | translate" fxLayout="row wrap" class="chip-list" role="listbox">
			<span>{{'Reports.Tags' | translate}}:</span>
				<mat-chip *ngFor=" let tag of viewModel.tags"><span [innerHtml]="highlight(tag)" [tabindex]="0"></span></mat-chip>
		</div>
	</div>
	<div fxLayout="row wrap" fxLayoutAlign="space-between flex-start" fxLayoutAlign.gt-sm="end start" fxLayoutGap.gt-sm="{{isBookmarkPage ? '5px' : '15px'}}">
		<button mat-stroked-button *ngIf="viewModel.model.linkToData" fxLayoutAlign="center" (click)="downloadData()" matTooltip="{{'Common.Notifications.DownloadData' | translate}}" attr.aria-label="{{'Common.Notifications.DownloadData' | translate}}">
			<mat-icon class="download-icon">get_app</mat-icon>
		</button>
		<button mat-stroked-button *ngIf="viewModel.properties.videoId; let hyperlink" fxLayoutAlign="center" (click)="ShowVideo()">
			<span>
				<mat-icon>slideshow</mat-icon> {{'Common.Controls.Video' | translate}}
			</span>
		</button>
		<button mat-stroked-button (click)=" toggleBookmark()">
			<span *ngIf="!isBookmarkPage">
				<mat-icon>{{ bookmarkIcon | async }}</mat-icon> {{'Common.Controls.Bookmark' | translate}}
			</span>
			<span *ngIf="isBookmarkPage">
				{{'Bookmarked.Remove' | translate}}
			</span>
		</button>

		<ng-container [ngSwitch]="reportDisplayType">
			<button 
				*ngSwitchCase="ReportDisplayType.powerBi"
				(click)="OpenReport(this.viewModel.url.address)" 
				matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}" 	
				fxFlex="100" 
				fxFlex.gt-sm="200px" 
				mat-flat-button 
				color="primary"
			>
				{{'Reports.LaunchReport' | translate}} 
			</button>

			<button 
				*ngSwitchCase="ReportDisplayType.pdfXlsx"
				matTooltip="{{'DownloadDataset.SelectFiles' | translate}}"
				[matMenuTriggerFor]="menu"
				fxFlex="100" 
				fxFlex.gt-sm="240px" 
				mat-flat-button color="primary" 
			>
				<span class="factbook-report-launch">
					{{'DownloadDataset.SelectFiles' | translate}} 
					<mat-icon>arrow_drop_down</mat-icon>
				</span>
			</button>

			<button 
				*ngSwitchCase="ReportDisplayType.pdf"
				(click)="OpenReportWindow(this.viewModel.properties.pdf.address)"
				matTooltip="{{'DownloadDataset.SelectFiles' | translate}}"
				fxFlex="100" 
				fxFlex.gt-sm="240px" 
				mat-flat-button color="primary" 
			>
				<span class="factbook-report-launch">
					{{'DownloadDataset.SelectFiles' | translate}} 
				</span>
			</button>

			<ng-container *ngSwitchCase="ReportDisplayType.zip">
				<button 
					*ngIf="!isMultipleFiles; else multipleFilesDownload"
					(click)="OpenReportWindow(this.viewModel.model.reportFile.address)"
					matTooltip="{{'DownloadDataset.SelectFiles' | translate}}"
					fxFlex="100" 
					fxFlex.gt-sm="240px" 
					mat-flat-button color="primary" 
				>
					<span class="factbook-report-launch">
						{{'DownloadDataset.SelectFiles' | translate}}
					</span>
				</button>

				<ng-template #multipleFilesDownload>
					<button 
						[matMenuTriggerFor]="multipleFilesDownloadMenu"
						[matMenuTriggerData]="{files: viewModel.downloadableFiles}"
						matTooltip="{{'DownloadDataset.SelectFiles' | translate}}"
						fxFlex="100" 
						fxFlex.gt-sm="240px" 
						mat-flat-button color="primary" 
					>
						<span class="factbook-report-launch">
							{{'DownloadDataset.SelectFiles' | translate}}
							<mat-icon>arrow_drop_down</mat-icon>
						</span>
					</button>
				</ng-template>
			</ng-container>

			<button 
				*ngSwitchDefault
				(click)="OpenReport(this.viewModel.url.address)" 
				matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}" 	
				fxFlex="100" 
				fxFlex.gt-sm="200px" 
				mat-flat-button 
				color="primary"
			>
				DEFAULT 
			</button>
		</ng-container>
		
		<ng-template #factbookTitle>
			<ng-container [ngSwitch]="reportDisplayType">
				<button 
					*ngSwitchCase="ReportDisplayType.pdf"
					mat-button 
					color="primary" 
					disableRipple="true" 
					appEllipsify 
					[innerHtml]="highlight(viewModel.title)" 
					(click)="OpenReportWindow(this.viewModel.properties.pdf.address)">
				</button>
				<button 
					*ngSwitchCase="ReportDisplayType.pdfXlsx"
					mat-button 
					color="primary" 
					disableRipple="true" 
					appEllipsify 
					[innerHtml]="highlight(viewModel.title)" 
					[matMenuTriggerFor]="menu">
				</button>
				<ng-container *ngSwitchCase="ReportDisplayType.zip">
					<button 
						*ngIf="!isMultipleFiles; else multipleFilesDownloadTitle"
						(click)="OpenReportWindow(this.viewModel.model.reportFile.address)"
						matTooltip="{{'DownloadDataset.SelectFiles' | translate}}"
						mat-button 
						color="primary" 
						disableRipple="true" 
						appEllipsify 
						[innerHtml]="highlight(viewModel.title)" 
					>
						<span class="factbook-report-launch">
							{{'DownloadDataset.SelectFiles' | translate}}
						</span>
					</button>
	
					<ng-template #multipleFilesDownloadTitle>
						<button 
							[matMenuTriggerFor]="multipleFilesDownloadMenu"
							[matMenuTriggerData]="{files: viewModel.downloadableFiles}"
							matTooltip="{{'DownloadDataset.SelectFiles' | translate}}"
							mat-button 
							color="primary" 
							disableRipple="true" 
							appEllipsify 
							[innerHtml]="highlight(viewModel.title)" 
						>
							<span class="factbook-report-launch">
								{{'DownloadDataset.SelectFiles' | translate}}
								<mat-icon>arrow_drop_down</mat-icon>
							</span>
						</button>
					</ng-template>
				</ng-container>

			</ng-container>
		</ng-template>

		<mat-menu #menu="matMenu">
			<ng-template matMenuContent>
				<button mat-menu-item (click)="OpenReportWindow(this.viewModel.properties.pdf.address)" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}" [attr.aria-label]="'Factbooks.Launch.PDF' | translate">PDF</button>
				<button mat-menu-item (click)="OpenReportWindow(this.viewModel.properties.xlsx.address)" matTooltip="{{'Common.Notifications.OpensInNewWindow' | translate}}" [attr.aria-label]="'Factbooks.Launch.XLSX' | translate">XLSX</button>
			</ng-template>
		</mat-menu>
		
		<mat-menu #multipleFilesDownloadMenu="matMenu" class="download-files-menu">
			<ng-template matMenuContent let-files="files">
				<button mat-menu-item 
					(click)="downloadAllReportFiles()" 
					matTooltip="{{'DownloadDataset.SelectFiles' | translate}}" 
					[attr.aria-label]="'DownloadDataset.DownloadAllReportFiles' | translate"
				>
					{{ 'DownloadDataset.AllReportFiles' | translate }}
				</button>
				<button 
					*ngFor="let file of files"
					(click)="OpenReportWindow(file.url.address)"
					matTooltip="{{ ('Common.Controls.Download' | translate) + ' ' + file.name }}"
					[attr.aria-label]="('Common.Controls.Download' | translate) + ' ' + file.name"
					mat-menu-item
				>
					{{file.name}}
				</button>
			</ng-template>
		</mat-menu>
	</div>
</mat-card>