<main [ngClass]="{'app-light-theme': !themeService.isDark, 'app-dark-theme': themeService.isDark}" aria-labelledby="subtitle">
	<!-- need or Sass doesn't match against theme changes -->
	<div fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="12px" class="banner">
		<a routerLink="/categories" [attr.aria-label]="('Home.Title' | translate)">
			<img fxHide.gt-md src="assets/img/{{themeService.isDark ? 'dark/' : '' }}open_sims_logo_sm.svg" alt="{{'Common.ImageDescriptions.OpenSIMSLogo' | translate}}">
			<img fxHide.lt-lg src="assets/img/{{themeService.isDark ? 'dark/' : '' }}open_sims_logo_md.svg" alt="{{'Common.ImageDescriptions.OpenSIMSLogo' | translate}}">
		</a>
		<span id="subtitle">{{'Home.SubHeader' | translate}}</span>
	</div>
	<app-breadcrumb></app-breadcrumb>
	<ng-container *ngIf="showWelcome()">
		<app-read-more [text]="'Home.Welcome' | translate" [maxLength]="50"></app-read-more>
	</ng-container>

	<div class="app-tabs">
		<nav mat-tab-nav-bar [attr.aria-label]="'Home.SectionSelection' | translate">
			<ng-container *ngIf="isWide(); else showMobileMenu" >
				<button mat-tab-link routerLink="/categories" routerLinkActive="active-link">
					<span>{{'Home.Sections.Categories.md' | translate}}</span>
				</button>
				<button mat-tab-link routerLink="/bookmarked" routerLinkActive="active-link">
					<span>{{'Home.Sections.Bookmarks.md' | translate}}</span>
				</button>
				<button mat-tab-link routerLink="/pirl" routerLinkActive="active-link">
					<span>{{'Factbooks.PIRL.Title' | translate}}</span>
				</button>
				<button mat-tab-link routerLink="/latest-reports-and-news" routerLinkActive="active-link">
					<span>{{'Home.Sections.LatestReportsAndNews.md' | translate}}</span>
				</button>
				<button #searchButton class="search-button" mat-tab-link routerLink="/search" routerLinkActive="active-link" aria-labelledby="search-button-caption">
					<mat-icon [ngClass]="{ 'tab-icon': !checkOverflow(searchButton),  'tab-icon-solo': checkOverflow(searchButton)}">search</mat-icon>
					<span id="search-button-caption" [hidden]="checkOverflow(searchButton)">{{'Home.Sections.Search' | translate}}</span>
				</button>
			</ng-container>
		</nav>

		<ng-template #showMobileMenu>
			<div class="home-menu-mobile-view" [attr.aria-label]="'Home.SectionSelection' | translate">	
				<button routerLink="/categories" routerLinkActive="home-menu-active"><span>{{'Home.Sections.Categories.sm' | translate}}</span></button>
				<button routerLink="/bookmarked" routerLinkActive="home-menu-active">{{'Home.Sections.Bookmarks.sm' | translate}}</button>
				<button routerLink="/pirl" routerLinkActive="home-menu-active">{{'Factbooks.PIRL.Title' | translate}}</button>
				<button routerLink="/latest-reports-and-news" routerLinkActive="home-menu-active">{{'Home.Sections.LatestReportsAndNews.sm' | translate}}</button>
			</div>
		</ng-template>

		<router-outlet></router-outlet>
	</div>

</main>