import { animate, query, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Report } from 'src/app/models/report.model';
import { ReportViewModel } from 'src/app/view-models/report-view-model';
import { MatPaginatorI18nService } from '../../shared/services/mat-paginator-i18n.service';
import { ThemeService } from '../../shared/services/theme.service';
import { ModalReportDetailsComponent } from '../modal-report-details/modal-report-details.component';

export interface SortBy {
	value: string;
	viewValue: string;
}

enum Sort {
	relevance = 'relevance',
	title = 'title',
	titleReverse = 'titleReverse',
	dataOwner = 'dataOwner',
	lastRefresh = 'lastRefresh',
}

@Component({
	selector: 'app-report-listing',
	templateUrl: './report-listing.component.html',
	styleUrls: ['./report-listing.component.scss'],
	providers: [
		{
			provide: MatPaginatorIntl,
			useClass: MatPaginatorI18nService,
		},
	],
	animations: [
		trigger('listStagger', [
			transition('* <=> *', [
				query(':leave', animate('250ms ease-out', style({ opacity: 0, transform: 'translateY(-100px)' })), {
					optional: true
				})
			])
		])
	],
})
export class ReportListingComponent
	implements OnInit, OnDestroy, AfterViewInit
{
	private _reports: ReportViewModel[];
	private _keywords: string;
	
	pirlDescription?: string;

	@Input() set reports(value: ReportViewModel[]) {
		this._reports = value;
		this.dataSource = new MatTableDataSource<ReportViewModel>(this._reports);

		this.pirlDescription = value[0]?.pirlReportCode 
			? this.translateService.instant(`Factbooks.PIRL.Descriptions.${value[0].pirlReportCode}`) 
			: null;

		this.sortDataSource();
	}
	get reports(): ReportViewModel[] {
		return this._reports;
	}
	
	@Input() set keywords(value: string) { 
		this._keywords = value;
	}
	get keywords(): string {
		return this._keywords;
	}

	@Input() hidePaginator = false;
	@Input() institution;

	@ViewChild(MatPaginator) paginator: MatPaginator;
	columnsToDisplay: string[] = ['id'];
	dataSource: MatTableDataSource<ReportViewModel>;

	public viewAsList = false;
	public isLowRes = false;
	hasDataOwner = true;

	lowValue = 0;
	highValue = this.hidePaginator ? 10000 : 5;

	pageSize = 5;
	hasPrevPage = false;
	hasNextPage = true;

	langSubscription$: Subscription = Subscription.EMPTY;

	sortControl = new FormControl('');
	sortBys: SortBy[];
	isBookmarkPage = true;
	isFactbookPage = true;

	get sortValue(): string {
		return this.sortControl.value;
	}

	@HostListener('window:resize', ['$event'])
	getScreenSize() {
		this.isLowRes = window.innerWidth < 1280;
	}

	constructor(
		public themeService: ThemeService,
		private dialog: MatDialog,
		private translateService: TranslateService,
		private router: Router,
		private cd: ChangeDetectorRef
	) {
		this.isLowRes = window.innerWidth < 1280;

		this.isBookmarkPage =
			this.router.routerState.snapshot.url.startsWith('/bookmarked');
		this.isFactbookPage =
			this.router.routerState.snapshot.url.startsWith('/pirl');
	}

	ngOnInit(): void {
		this.hasDataOwner = this._reports.filter((r1) => r1.isModelOfType(Report)).length > 0;

		this.dataSource = new MatTableDataSource<ReportViewModel>(this._reports);
	}

	ngAfterViewInit() {
		if (this.paginator) {
		this.dataSource.paginator = this.paginator;
		this.dataSource.paginator.initialized.subscribe(() => {
			this.hidePaginator = this.hidePaginator ||
				(this.paginator.getNumberOfPages() < 2 && 
					this.paginator.pageSize === 5);
			});
		}
		this.cd.detectChanges();
	}

	sortDataSource() {
		this.setSortByOptions();
		this.setDefaultSortByValue();
		this.onSortByChange();
	}

	setSortByOptions() {
		this.sortBys = [];
		if (this.router.routerState.snapshot.url.startsWith('/search')) {
			this.sortBys.push({
				value: Sort.relevance,
				viewValue: this.translateService.instant(
				'Common.Controls.SortBy.Relevance'
				),
			});
		}
		if (this.isFactbookPage &&
				this.router.routerState.snapshot.url.includes('selectedReportType=Factbooks')) {
			this.sortBys.push({
				value: Sort.title,
				viewValue: this.translateService.instant('Common.Controls.SortBy.FactbookTitle'),
			});
			this.sortBys.push({
				value: Sort.titleReverse,
				viewValue: this.translateService.instant('Common.Controls.SortBy.ReverseFactbookTitle'),
			});
		} else {
			this.sortBys.push({
				value: Sort.title,
				viewValue: this.translateService.instant('Common.Controls.SortBy.ReportTitle'),
			});
			this.sortBys.push({
				value: Sort.titleReverse,
				viewValue: this.translateService.instant('Common.Controls.SortBy.ReverseReportTitle'),
			});
		}
		if (this.hasDataOwner) {
			this.sortBys.push({
				value: Sort.dataOwner,
				viewValue: this.translateService.instant('Common.Controls.SortBy.DataOwner'),
			});
		}

		this.sortBys.push({
			value: Sort.lastRefresh,
			viewValue: this.translateService.instant('Common.Controls.SortBy.LastRefresh'),
		});
	}

	setDefaultSortByValue() {
		this.sortControl.setValue(
			this.router.routerState.snapshot.url.startsWith('/search')
				? Sort.relevance
				: this.isFactbookPage
					? Sort.titleReverse
					: Sort.title
			);
	}

	ngOnDestroy(): void {
		this.langSubscription$.unsubscribe();
	}

	onSortByChange() {
		if (this.sortValue === Sort.dataOwner) {
			// For dataOwner sorts, first sort by title so that we always get the same ordering
			// for dataOwner sorts. Otherwise the prior sort affects the ordering of dataOwner.
			this.Sort(Sort.title);
		}
		this.Sort(this.sortValue);
	}

	Sort(sortOrder) {
		sortOrder = sortOrder
			? sortOrder
			: this.router.routerState.snapshot.url.startsWith('/search')
				? Sort.relevance
				: Sort.title;

		this._reports = this._reports.sort((x, y) => {
			switch (sortOrder) {
				case Sort.relevance:
					return this.stringSort(y.properties.sort, x.properties.sort);
				case Sort.dataOwner:
					return this.stringSort(
						x.dataOwner?.description,
						y.dataOwner?.description
					);
				case Sort.lastRefresh:
					return this.stringSort(y.published, x.published, false);
				case Sort.titleReverse:
					return this.stringSort(y.title, x.title);
				default: // title
					return this.stringSort(x.title, y.title);
			}
		});
		this.dataSource = new MatTableDataSource<ReportViewModel>(this._reports);
		this.dataSource.paginator = this.paginator;
	}

	stringSort(x, y, blanksFirst = true) {
		if (blanksFirst) {
			return !x ? 1 : !y ? -1 : x < y ? -1 : 1;
		}
		return !x ? -1 : !y ? 1 : x < y ? -1 : 1;
	}

	openReportDetailModal(report: ReportViewModel) {
		const dialogRef = this.dialog.open(ModalReportDetailsComponent, {
			width: '100% !important',
			panelClass: ['my-panel', 'report-card-panel'],
			data: {},
		});

		dialogRef.componentInstance.viewModel = report;
		dialogRef.componentInstance.reports = this._reports;
		dialogRef.componentInstance.keywords = this._keywords;
	}

	public getPaginatorData(event: PageEvent): PageEvent {
		this.lowValue = event.pageIndex * event.pageSize;
		this.highValue = this.lowValue + event.pageSize;
		this.pageSize = event.pageSize;

		this.paginator.pageIndex = event.pageIndex;
		this.paginator.pageSize = event.pageSize;

		this.syncPaginatorsButtons();
		this.dataSource.paginator = this.paginator;

		return event;
	}

	get pages(): number {
		return this.paginator
		? this.paginator.getNumberOfPages()
		: Math.trunc(this._reports.length / 5) +
			(this._reports.length % 5 > 0 ? 1 : 0);
	}

	get getPage(): number {
		return this.paginator ? this.paginator.pageIndex + 1 : 1;
	}

	pageToIndex(pageIndex) {
		if (pageIndex < 0) {
			pageIndex = pageIndex * -1;
		}
		this.paginator.pageIndex = pageIndex - 1;

		this.paginator.pageIndex = pageIndex - 1, // number of the page you want to jump.
		this.paginator.page.next({
			pageIndex: pageIndex - 1,
			pageSize: this.paginator.pageSize,
			length: this.paginator.length,
		});
		this.syncPaginatorsButtons();
	}

	syncPaginatorsButtons() {
		this.hasPrevPage = this.paginator.hasPreviousPage();
		this.hasNextPage = this.paginator.hasNextPage();
	}

	nextPage() {
		this.paginator.nextPage();
		this.syncPaginatorsButtons();
	}

	previousPage() {
		this.paginator.previousPage();
		this.syncPaginatorsButtons();
	}
}
