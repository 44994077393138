import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { AccessibilityComponent } from './components/accessibility/accessibility.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import { ApprovalWorkflowComponent } from './components/approval-workflow/approval-workflow.component';
import { BookmarksComponent } from './components/bookmarks/bookmarks.component';
import { CategoryComponent } from './components/category/category.component';
import { ContactComponent } from './components/contact/contact.component';
import { DatasetUploaderComponent } from './components/dataset-uploader/dataset-uploader.component';
import { DatasetDownloaderComponent } from './components/dataset-downloader/dataset-downloader.component';
import { DataFilesManagementComponent } from './components/data-files-management/data-files-management.component';
import { FactbooksComponent } from './components/factbooks/factbooks.component';
import { GlossaryComponent } from './components/glossary/glossary.component';
import { HelpComponent } from './components/help/help.component';
import { LatestReportsAndNewsComponent } from './components/latest-reports-and-news/latest-reports-and-news.component';
import { LoginComponent } from './components/login/login.component';
import { NoInternetComponent } from './components/no-internet/no-internet.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ProjectDatasetsComponent } from './components/project-datasets/project-datasets.component';
import { ReportCategoriesComponent } from './components/report-categories/report-categories.component';
import { ReportRefreshComponent } from './components/report-refresh/report-refresh.component';
import { ReportViewComponent } from './components/report-view/report-view.component';
import { SearchComponent } from './components/search/search.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { TermsComponent } from './components/terms/terms.component';
import { VideoGalleryComponent } from './components/video-gallery/video-gallery.component';
import { AuthGuard } from './services/guards/auth-guard';
import { DatasetManagerComponent } from './components/dataset-manager/dataset-manager.component';
import { UserGuidesComponent } from './components/user-guides/user-guides.component';
import { ApprovalWorkflowProdComponent } from './components/approval-workflow-prod/approval-workflow-prod.component';
import { UatTransferComponent } from './components/uat-transfer/uat-transfer.component';
import { ApprovalWorkflowUatComponent } from './components/approval-workflow-uat/approval-workflow-uat.component';
import { DatasetStakeholderSelectorComponent } from './components/dataset-stakeholder-selector/dataset-stakeholder-selector.component';
import { UserAuditComponent } from './components/user-audit/user-audit.component';

const routes: Routes = [
	{
		path: '',
		 redirectTo: 'sign-in',
		// component: AppComponent,
		pathMatch: 'full',
	},
	{
		path: 'categories',
		canActivate: [AuthGuard],
		component: ReportCategoriesComponent,
		data: { breadcrumb: "Home.Sections.Categories.md" },
		children: [
			{
				path: 'category',
				component: CategoryComponent,
				data: { breadcrumb: ""},
			},
		]
	},
	{
		path: 'report-view/:id',
		canActivate: [AuthGuard],
		component: ReportViewComponent
	},
	{
		path: 'sign-up',
		component: SignUpComponent,
		data: { breadcrumb: "SignUp.Title" },
	},
	{
		path: 'sign-in',
		component: LoginComponent,
		data: { breadcrumb: "Login.Title" },
	},
	{
		path: 'bookmarked',
		canActivate: [AuthGuard],
		component: BookmarksComponent,
		data: { breadcrumb: "Bookmarked.Title" },
	},
	{
		path: 'pirl',
		canActivate: [AuthGuard],
		component: FactbooksComponent,
		data: { breadcrumb: "Factbooks.PIRL.Title2" },
	},
	{
		path: 'factbooks',
		redirectTo: '/pirl', 
		pathMatch: 'prefix'
	},
	{
		path: 'admin',
		canActivate: [AuthGuard],
		component: AdminDashboardComponent,
		data: { breadcrumb: "Admin.Title" },
	},
	{
		path: 'latest-reports-and-news',
		canActivate: [AuthGuard],
		component: LatestReportsAndNewsComponent,
		data: { breadcrumb: "LatestReportsAndNews.Title" },
	},
	{
		path: 'search',
		canActivate: [AuthGuard],
		component: SearchComponent,
		data: { breadcrumb: "Search.Title" },
	},
	{
		path: 'about',
		canActivate: [AuthGuard],
		component: AboutComponent,
		data: { breadcrumb: "AboutUs.Title" },
	},
	{
		path: 'help',
		canActivate: [AuthGuard],
		component: HelpComponent,
		data: { breadcrumb: "Help.Title" },
	},
	{
		path: 'accessibility',
		canActivate: [AuthGuard],
		component: AccessibilityComponent,
		data: { breadcrumb: "Accessibility.Title" },
	},
	{
		path: 'terms',
		canActivate: [AuthGuard],
		component: TermsComponent,
		data: { breadcrumb: "Terms.Title" },
	},
	{
		path: 'contact',
		canActivate: [AuthGuard],
		component: ContactComponent,
		data: { breadcrumb: "ContactUs.Title" },
	},
	{
		path: 'glossary',
		canActivate: [AuthGuard],
		component: GlossaryComponent,
		data: { breadcrumb: "Glossary.Title" },
	},
	{
		path: 'videos',
		canActivate: [AuthGuard],
		component: VideoGalleryComponent,
		data: { breadcrumb: "VideoGallery.Title" },
	},
	{
		path: '404',
		component: PageNotFoundComponent,
		data: { breadcrumb: "PageNotFound.Title" },
	},
	{
		path: 'no-internet',
		canActivate: [AuthGuard],
		component: NoInternetComponent,
		data: { breadcrumb: "NoInternet.Title" },
	},
	{
		path: 'download-dataset',
		canActivate: [AuthGuard],
		component: DatasetDownloaderComponent,
		data: { breadcrumb: "DownloadDataset.DownloadTitle" },
	},
	{
		path: 'project-datasets',
		canActivate: [AuthGuard],
		component: ProjectDatasetsComponent,
		data: { breadcrumb: "DownloadDataset.DownloadTitle" },
	},
	{
		path: 'upload-dataset',
		canActivate: [AuthGuard],
		component: DatasetUploaderComponent,
		data: { breadcrumb: "DownloadDataset.UploadTitle" },
	},
	{
		path: 'manage-datasets',
		canActivate: [AuthGuard],
		component: DatasetManagerComponent,
		data: { breadcrumb: "DownloadDataset.UploadTitle" },
	},
	{
		path: 'select-stakeholder',
		canActivate: [AuthGuard],
		component: DatasetStakeholderSelectorComponent,
		data: { breadcrumb: "DownloadDataset.UploadTitle" },
	},
	{
		path: 'data-files-management',
		canActivate: [AuthGuard],
		component: DataFilesManagementComponent,
		data: { breadcrumb: "DataFilesManagement.Title" },
	},
	{
		path: 'reports-refresh',
		canActivate: [AuthGuard],
		component: ReportRefreshComponent,
		data: { breadcrumb: "ReportRefresh.Title" },
	},
	{
		path: 'approval-workflow',
		canActivate: [AuthGuard],
		component: ApprovalWorkflowComponent,
		data: { breadcrumb: "ApprovalWorkflow.Title" },
	},
	{
		path: 'user-guides',
		canActivate: [AuthGuard],
		component: UserGuidesComponent,
		data: { breadcrumb: "UserGuides.Title" },
	},
	{
		path: 'prod-workflow',
		canActivate: [AuthGuard],
		component: ApprovalWorkflowProdComponent,
		data: { breadcrumb: "ApprovalWorkflow.Title" },
	},
	{
		path: 'uat-workflow',
		canActivate: [AuthGuard],
		component: ApprovalWorkflowUatComponent,
		data: { breadcrumb: "ApprovalWorkflow.Title" },
	},
	{
		path: 'uat-transfer',
		canActivate: [AuthGuard],
		component: UatTransferComponent,
		data: { breadcrumb: "ApprovalWorkflow.Title" },
	},
	{
		path: 'user-audit',
		canActivate: [AuthGuard],
		component: UserAuditComponent,
		data: { breadcrumb: "UserAudit.Title" },
	},
	{
		path: "**",
		redirectTo: '/404'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true, scrollPositionRestoration: "enabled", relativeLinkResolution: 'legacy' })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
